
import axios from "axios";
import '@babel/polyfill';


export default class getData {

     constructor(query) {
         this.query = query
     }

     async getResults(query) {

        try {
            const res = await axios(this.query);
            
            // console.log(res.data);
            this.result = res.data;
        } catch (error) {
            alert(error);
        }
        
    }
}



