import axios from "axios";
import {
    elements
} from "./base.js";
import getData from "./getItems";
import * as view from "./View";
import item from "./Model";
import {
    countBy
} from "lodash";


view.focusInput();

const state = {
    puvodniText: "",
    items: "",
    // deleteItem: "/nakupniSeznam/_assets/php/deleteItem.php",
    // saveItem: "/nakupniSeznam/_assets/php/saveItem.php",
    // getItems: "/nakupniSeznam/_assets/php/getItems.php",
    // updateItem: "/nakupniSeznam/_assets/php/updateItem.php",
    // updateFav: "/nakupniSeznam/_assets/php/updateFav.php",
    // addFav: "/nakupniSeznam/_assets/php/addFav.php",
    // getFavs: "/nakupniSeznam/_assets/php/getFavs.php",
    // deleteFav: "/nakupniSeznam/_assets/php/deleteFav.php",
    // addBought: "/nakupniSeznam/_assets/php/addBought.php",
    // getBought: "/nakupniSeznam/_assets/php/getBought.php"



    // ----------------------------------------------------------
    // production paths
    // ----------------------------------------------------------

    deleteItem: "/_assets/php/deleteItem.php",
    saveItem: "/_assets/php/saveItem.php",
    getItems: "/_assets/php/getItems.php",
    updateItem: "/_assets/php/updateItem.php",
    updateFav: "/_assets/php/updateFav.php",
    addFav: "/_assets/php/addFav.php",
    getFavs: "/_assets/php/getFavs.php",
    deleteFav: "/_assets/php/deleteFav.php",
    addBought: "/_assets/php/addBought.php",
    getBought: "/_assets/php/getBought.php"
};

const axiosFunc = (data, path) => {
    axios.post(path, {
        data: data
    }).then(
        function (response) {
            console.log(response.data);
        }
    ).catch(function (error) {
        console.error(error);
    });
};

const formatDate = (date) => {
    let hours = date.getHours(),
        minutes = date.getMinutes(),
        day = date.getDate(),
        month = date.getMonth() + 1,
        year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

const getItems = async () => {

    state.items = new getData(state.getItems);

    try {
        await state.items.getResults();
    } catch (err) {
        alert(err)
    }

    state.items.result.map(e => {
        state.item = new item(e.item_text, e.item_category, e.item_ID, e.item_favourite);
        view.setEl(e.item_category, state.item.createHTML());
        // if(e.item_favourite == 1) {
        //     console.log("fav" + state.item);
        // }
    });

};

const getFavs = async () => {

    state.favs = new getData(state.getFavs);

    try {
        await state.favs.getResults();
    } catch (err) {
        alert(err)
    }
    state.favs.result.map(e => {
        view.addFav(e.fav_category, e.fav_text, e.favouriteID);
    });

};

const getBought = async () => {

    state.bought = new getData(state.getBought);

    try {
        await state.bought.getResults();
    } catch (err) {
        alert(err)
    }

    state.bought.result.map(e => {
        view.addBought(e.itemID, e.item_category, e.item_text, e.item_date);

    });

};

window.addEventListener("load", () => {

    getItems();
    getFavs();
    getBought();
    // console.log(state.favs);

});

const createID = () => {
    let date = Date.now();

    let uniqueID = "x" + Math.random().toString(36).substr(2, 9) + date;

    return uniqueID;
}


// form submit action - add element, get input value, category, create ID
elements.form.addEventListener("submit", (e) => {
    e.preventDefault();
    let input = view.getInput();
    let category = view.getCategory();

    let date = new Date();

    if (input) {
        state.item = new item(input, category, createID());
        view.setEl(category, state.item.createHTML());
        state.item.getDate();
    }

    view.clearInput();

    // console.log(state);

    axiosFunc(state.item, state.saveItem);

});


elements.allItems.addEventListener("click", e => {


    if (e.target.matches(".category__heading")) {

        let parent = e.target.parentElement;
        let itemsUl = parent.children[1];

        if (itemsUl.children.length > 0) {


            const ulHeight = itemsUl.offsetHeight;

            let itemsLiLength = itemsUl.children.length;
            let itemsLiHeight = itemsUl.children[0].offsetHeight;
            let originalHeight = itemsLiLength * itemsLiHeight;

            if (ulHeight > 2) {
                itemsUl.style.height = 0;
            } else if (ulHeight < 2) {
                itemsUl.style = `height: ${originalHeight}px`;
            }
        }
    }

});


/*
---------------------------------------------------------
    REMOVING, RENAMING, ADDING TO FAVOURITES
---------------------------------------------------------
*/
elements.allItems.addEventListener("click", e => {
    e.preventDefault();

    // GET TARGET
    let elementClass = e.target;


    // IF IS CLICKED ON REMOVE
    if (elementClass.classList.contains("item__remove")) {

        // GET ID OF DELETED ELEMENT
        let deleteID = elementClass.parentNode.parentNode.id;

        // DELETE FROM DATABASE
        axiosFunc(deleteID, state.deleteItem);

        // DELETE ELEMENT FROM LIST
        state.item.removeElement(elementClass);

        // IF CLICKED RENAME
    } else if (elementClass.classList.contains("item__rename")) {

        // SAVE ORIGINAL TEXT
        let text = state.item.puvodniText(elementClass);
        state.puvodniText = text;
        state.item.renameElement(elementClass);

    } else if (elementClass.classList.contains("item__save")) {

        let item = {
            ID: elementClass.parentNode.parentNode.id
        };


        let changed = state.item.changeElement(elementClass);


        item.changed = changed;

        // changed is new changed text
        // UPDATE NEW TEXT TO DATABASE
        axiosFunc(item, state.updateItem);

        // IF CLICKED ON CANCEL DURING RENAME
    } else if (elementClass.classList.contains("item__cancel")) {

        // CANCEL CHANGE AND PUT BACK THE ORIGINAL TEXT
        state.item.cancelChange(state.puvodniText, elementClass);
    }

    // IF CLICKED STAR TO ADD ELEMENT INTO FAVOURITES
    if (elementClass.classList.contains("btn__star")) {

        // GET LI ELEMENT
        let liItem = elementClass.parentNode.parentNode;

        // GET CATEGORY TYPE
        let categoryType = liItem.dataset.typ;

        // GET ID OF ELEMENT
        let itemID = liItem.id;

        let inputVal;
        // IF LI ELEMENT CONTAINS ANY TEXT
        if (elementClass.parentNode.parentNode.children[0].classList.contains("items__left")) {

            // GET TEXT FROM LI ELEMENT
            inputVal = elementClass.parentNode.parentNode.children[0].querySelector("label").innerText;
        }

        // CREATE NEW UNIQUE ID FOR FAVOURITE ELEMENT
        let favID = createID();

        // check if element has favourite inside
        // if not, create a new one
        // if yes, put element inside the favourite wrap
        view.addFav(categoryType, inputVal, favID);

        // add class Fav to listItem
        liItem.classList.add("fav");



        // favourite object
        let favObj = {
            favID: favID,
            fav_text: inputVal,
            fav_cat: categoryType
        }

        // UPDATE DATABASE AND SET THAT ELEMENT IS IN FAVOURITES
        axiosFunc(itemID, state.updateFav);

        //add to favourites table if it's not there, else remove it
        axiosFunc(favObj, state.addFav);

    }


    // IF CLICK ON LI ELEMENT -> CHECK CHECKBOX
    if (elementClass.classList.contains("items__li")) {
        let checkbox = elementClass.children[0].querySelector(".item__text");

        if (checkbox.checked == true) {
            checkbox.checked = false;
        } else {
            checkbox.checked = true;
        }
    }

});



// show favourites items after click
elements.categories.addEventListener("click", e => {


    let target = e.target;

    // check if clicked element is button
    if (target.matches(".categories__btn")) {


        elements.inputCategory.value = target.id;

        let favourites = e.target.parentElement.getElementsByClassName("favourites")[0];

        //check if clicked elements has favourites
        if (favourites != undefined) {

            //fade out / fade in toggle class
            favourites.classList.toggle("show-favourites");

            // get all btns and set them faded
            let allCat = [...document.getElementsByClassName("categories__btn")];
            allCat.forEach(el => el.classList.toggle("faded"));

            // remove faded class and add active class for the clicked btn
            favourites.parentElement.children[0].classList.remove("faded");
            favourites.parentElement.children[0].classList.toggle("active");


            // get wrap and favourite list height
            let wrapHeight = favourites.offsetHeight;
            let ulHeight = favourites.getElementsByClassName("favourites__ul")[0].offsetHeight;

            // set wrap heigh to 0 if favourite list is faded
            if (wrapHeight == ulHeight) {

                setTimeout(() => {
                    favourites.style.height = 0;
                }, 700);

            } else if (wrapHeight == 0) {
                favourites.style = `height: ${ulHeight}px`;
            }
        }

    }


    if (target.matches(".favourite")) {

        let input = target.querySelector("p").innerText,
            category = target.dataset.typ;

        state.item = new item(input, category, createID(), 1);
        view.setEl(category, state.item.createHTML());
        axiosFunc(state.item, state.saveItem);

    }

    // favourites click
    if (target.matches(".item__remove")) {

        let favID = target.parentElement.id;
        axiosFunc(favID, state.deleteFav);
        document.getElementById(favID).remove();



    }

});

elements.finish.addEventListener("click", e => {

    let checked = document.querySelectorAll("input[type=checkbox]:checked");

    if (checked.length > 0) {
        checked.forEach((e) => {

            let liItem = e.parentElement.parentElement

            // get values
            let id = liItem.id,
                itemText = e.nextElementSibling.innerText,
                itemCat = liItem.dataset.typ,
                date = formatDate(new Date());

            //  CREATE ITEM OBJECT FOR DATABASE
            let item = {
                id,
                itemText,
                itemCat,
                date
            }

            //add element to bought table
            axiosFunc(item, state.addBought);

            // add to bought list
            view.addBought(item.id, item.itemCat, item.itemText, item.date);

            // DELETE FROM ITEMS DATABASE
            axiosFunc(id, state.deleteItem);

            // delete element from list
            liItem.remove();

        });

    }

});


elements.showBought.addEventListener("click", e => {

    elements.bought.classList.remove("hideBought");
    document.querySelector("body").classList.add("overlay");


});

elements.boughtCross.addEventListener("click", e => {
    elements.bought.classList.add("hideBought");
    document.querySelector("body").classList.remove("overlay");

});


