
import {elements} from "./base.js";
import { create } from "lodash";


export const getInput = () => elements.input.value;

export const getCategory = () => elements.inputCategory.value;

export const setEl = (category, html) => {
    document.querySelector(`#cat-${category}`).children[1].insertAdjacentHTML("beforeend", html);
    // document.querySelector(`#cat-${category}`).children[1].insertAdjacentHTML("beforeend", html);

    // elements.list.insertAdjacentHTML("beforeend", html);
};

export const clearInput = () => {
    elements.input.value = "";
};

export const focusInput = () => {
    elements.input.focus();
};

export const showFav = () => {
    elements.favourites.classList.add("show-favourites");
};

export const addFav = (categoryType, inputVal, favID) => {

    let btn = document.getElementById(categoryType);

    if (btn.parentElement.children.length == 1) {
        btn.parentElement.insertAdjacentHTML("beforeend", createFavs(categoryType));
    }

    if(btn.parentElement.children.length == 1 && btn.parentElement.classList.contains("favourites")) {

        btn.parentElement.children[1].children[0].insertAdjacentHTML("beforeend", favHTML(categoryType, inputVal, favID));

    } else {
        btn.parentElement.children[1].children[0].insertAdjacentHTML("beforeend", favHTML(categoryType, inputVal, favID));
    }

};

const favHTML = (categoryType, inputVal, favID) => {
    let html = `<li class="${categoryType}__favourite favourite" id="${favID}" data-typ="${categoryType}"><p>${inputVal}</p><a href="#" class="item__remove btn">
    <svg class="icon icon_bin">
    ${elements.svgIcon("icon-bin")}
</svg></a></li>`;
    return html;
};

const createFavs = (categoryType) => {
    let html = `<div class="favourites">
    <ul class="${categoryType}__favourites favourites__ul ">
        
    </ul>
</div>`;

return html;
};

const boughtItem = (id, type, text, date) => {
    let item = `<li class="bought__item items__li" id="${id}" data-typ="${type}">
    <p class="item__text">${text}</p>
    <p class="date__item">${date}</p>
</li>`;

    return item;
}

export const addBought = (id, type, text, date) => {

    const boughtList = document.querySelector(".bought__list");
    
    boughtList.insertAdjacentHTML("afterbegin", boughtItem(id, type, text, date));


};

