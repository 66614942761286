export const elements = {
    form: document.querySelector(".form"),
    list: document.querySelector(".items__ul"),
    input: document.querySelector(".form__input"),
    inputCategory: document.querySelector(".form__category"),
    inputChange: document.querySelector(".input__change"),
    svgIcon: function(iconClass) {
        return `<use xlink:href="sprite.149a1675.svg#${iconClass}"></use>`
    },
    favourites: document.querySelector(".favourites"),
    categories: document.querySelector(".categories__ul"),
    favourite: document.querySelector(".btn__star"),
    finish: document.querySelector(".finish__btn"),
    showBought: document.querySelector(".showBought"),
    boughtCross: document.querySelector(".bought__cross"),
    bought: document.querySelector(".bought"),
    item: document.querySelector(".items__li"),
    allItems: document.querySelector(".items")
};

///sprite.149a1675.svg#icon-pencil