import {elements} from "./base";
export default class Item {



    constructor(itemText, category, id, favourite = 0) {
        this.itemText = itemText,
        this.favourite = favourite,
        this.category = category,
        this.id = id
    }

    

    //create html
    createHTML() {
        let html = `<li class="items__li item ${(this.favourite == 1) ? "fav" : "ff"} " id="${this.id}" data-typ="${this.category}">
        <div class="items__left">
    <input type="checkbox" id="item" class="item__text">
<label for="item">${this.itemText}</label>
</div>
<div class="items__right">
    <a href="#" class="btn__star btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_star">
            ${elements.svgIcon("icon-star-full")}
        </svg>
    </a>
    <a href="#" class="item__remove btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_bin">
            ${elements.svgIcon("icon-bin")}
        </svg>
    </a>
    <a href="#" class="item__rename btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_pencil">
        ${elements.svgIcon("icon-pencil")}
        </svg>
    </a>
</div>
    </li>`;


    return html;
    }

    //remove element from ul list

    removeElement(element) {
        let clickedElement = element.parentNode.parentNode; 
        let parentElement = element.parentNode.parentNode.parentNode; 
            parentElement.removeChild(clickedElement);
    }


    // create rename HTML input
    renameHTML(text) {
        let renameHTML = `<input type="text" class="input__change" value="${text}">
        <div class="items__right">

        <a href="#" class="item__save btn">
                                    <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_pencil">
                                        ${elements.svgIcon("icon-pencil")}
                                    </svg>
                                </a>

        <a href="#" class="item__cancel btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_cross">
        ${elements.svgIcon("icon-cross")}
                                    </svg></a>
    </div>`;
    return renameHTML;
    }

    // get text and put it into rename input
    renameElement(element) {
        let text = element.parentNode.parentNode.getElementsByTagName("label")[0].innerHTML;
        element.parentNode.parentNode.innerHTML = this.renameHTML(text);
    }

    //get original text
    puvodniText(element) {
        let text = element.parentNode.parentNode.getElementsByTagName("label")[0].innerHTML;
        return text; 
    }


    changeHTML(text) {
        let renameHTML = `<div class="items__left">
        <input type="checkbox" id="item" class="item__text">
        <label for="item">${text}</label>
        </div>
        <div class="items__right">
        <a href="#" class="btn__star btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_star">
            ${elements.svgIcon("icon-star-full")}
        </svg>
    </a>
    <a href="#" class="item__remove btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_bin">
        ${elements.svgIcon("icon-bin")}
    </svg></a>
    <a href="#" class="item__rename btn">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="icon icon_pencil">
            ${elements.svgIcon("icon-pencil")}
        </svg>
    </a>
        </div>`;
    return renameHTML;
    }

    // change input to new text
    changeElement(element) {
        let changedText = element.parentNode.parentNode.getElementsByTagName("input")[0].value;
        element.parentNode.parentNode.innerHTML = this.changeHTML(changedText);

        return changedText;
    }

    cancelChange(puvodniText, element) {
        element.parentNode.parentNode.innerHTML = this.changeHTML(puvodniText);
    }

    getDate() {
        console.log(this.date);
    }

    // check if there is any checked item and create a finish button
    isChecked(item) {

        //create node list of checked elements
        let checkedItems = document.querySelectorAll("input:checked");

        if(checkedItems) {



        }
    }






}

